<template>
  <div>
    <ModuleComponent :module-data="moduleData" @changed="item = $event">
      <template v-slot:tableFilters="{ filters }"></template>
      <template v-slot:form="{ item }">
        <b-row>
          <b-col cols="12" sm="12" md="12">
            <b-form-group :label="t('GLOBAL.ACCOUNT')">
              <v-select2
                v-model="item.accountId"
                :text="item.account && item.account ? item.account.name : ''"
                endPoint="accounts"
                :placeholder="t('GLOBAL.ACCOUNT')"
                sort="name"
              ></v-select2>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="12" md="12">
            <b-form-group :label="t('GLOBAL.DATE')">
              <BDatePicker
                :id="'startDate'"
                v-model="item.date"
                @input="item.date = $event"
                hide-header
                show-decade-nav
                :placeholder="t('GLOBAL.DATE')"
              >
              </BDatePicker>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" sm="12" md="12">
            <b-form-group :label="t('GLOBAL.TYPE')">
              <span>{{ item.type }}</span>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" sm="12" md="12">
            <b-form-group :label="t('GLOBAL.REQUEST')">
              <b-form-textarea v-model="item.request"></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" sm="12" md="12">
            <b-form-group :label="t('GLOBAL.RESPONSE')">
              <b-form-textarea v-model="item.response"></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
      </template>
    </ModuleComponent>
  </div>
</template>

<script>
// http://core.datadns.net:5000/swagger/index.html#model-ModuleGroup
export default {
  name: "denizbankRequests",
  data() {
    return {
      item: {},
      moduleData: {
        name: "denizbankRequests",
        primaryKey: "id",
        table: {
          sortBy: "date",
          sortDesc: true,
          graphqlQuery: `
							id,account{name},date,type
						`,
          headers: [
            { text: "ID", value: "id", width: "70" },
            { text: this.t("GLOBAL.ACCOUNT"), value: "account.name" },
            { text: this.t("GLOBAL.DATE"), value: "date" },
            { text: this.t("GLOBAL.TYPE"), value: "type" }
          ]
        },
        form: {
          maxWidth: "80%"
        }
      }
    };
  }
};
</script>
